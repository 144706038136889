<template>
    <div v-if="flag == 0">
        <small class="badge badge-pill badge-warning">Ditinjau Ulang</small>
    </div>

    <div v-else-if="flag == 1 || flag == 16">
        <small class="badge badge-pill badge-primary">Disetujui (1/2)</small>
    </div>

    <div v-else-if="flag == 2">
        <small class="badge badge-pill badge-primary text-white">Disetujui (2/2)</small>
    </div>

    <div v-else-if="flag == 3">
        <small class="badge badge-pill badge-info text-white">Pengajuan Diproses</small>
    </div>

    <div v-else-if="flag == 4">
        <small class="badge badge-pill badge-success text-white">Pengajuan selesai</small>
    </div>

    <div v-else-if="flag == 10">
        <small class="badge badge-pill badge-dark">Pengajuan Ditolak</small>
    </div>

    <div v-else-if="flag == 11">
        <small class="badge badge-pill badge-danger">Dijadwalkan Ulang</small>
    </div>

    <div v-else-if="flag == 12">
        <small class="badge badge-pill badge-danger">Menunggu Penilaian</small>
    </div>
</template>

<script>

export default {
    props: ["flag"]
}
</script>