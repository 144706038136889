<template>
    <div>
        <div class="row">
            <div class="col-lg-4">
                <b-form @submit.prevent="handleSearch">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button @click="$bvModal.show('m-filter')" variant="pgiBtn">
                                <i class="fa fa-filter"></i> Cari
                            </b-button>
                        </b-input-group-prepend>
                        <b-form-input @change="setQuery('im_number', $event)" placeholder="Cari No Pengajuan"
                            v-model="selectedImNumber"></b-form-input>
                        <b-input-group-append>
                            <b-button type="submit" variant="pgiBtn"><i class="fa fa-search"></i></b-button>
                        </b-input-group-append>
                        <b-input-group-append>
                            <b-button variant="dark" @click="onResetQuery">
                                <i class="fa fa-refresh"></i>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form>
            </div>
        </div>

        <b-modal id="m-filter" size="lg" title="Filter Internal Memo" no-close-on-backdrop no-close-on-esc hide-footer>
            <b-form @submit.prevent="handleSearch">
                <div class="row">
                    <div class="col-lg-4">
                        <b-form-group label="Filter tanggal">
                            <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true"
                                :showDropdowns="true" :autoApply="true" @select="handleSelectDate">
                            </date-range-picker>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Kota/Kabupaten">
                            <div class="form-group">
                                <multiselect @select="setQuery('kabupaten_kota_id', $event)" class="w-100"
                                    placeholder="Pilih Kabupaten/Kota" label="text" track-by="id" v-model="selectKabKot"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                                    :options="filtered_cities"></multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Cabang">
                            <div class="form-group">
                                <multiselect @select="setQuery('id_cabang_multiple', $event)" class="w-100"
                                    placeholder="Pilih Cabang" label="text" track-by="id" v-model="selectedCabang"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="true"
                                    :options="cabangLists">
                                </multiselect>
                            </div>
                        </b-form-group>
                    </div>

                    <div class="col-lg-4">
                        <b-form-group label="Jenis Pengajuan">
                            <div class="form-group">
                                <multiselect class="w-100" @select="setQuery('id_kategori_jenis_fpp', $event)"
                                    placeholder="Jenis Pengajuan" label="name" track-by="id"
                                    v-model="selectedJenisPengajuan" :show-labels="false" :close-on-select="true"
                                    :clear-on-select="false" :multiple="false" :options="kategori_jenis"></multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <b-form-group label="Pengajuan">
                            <div class="form-group">
                                <multiselect @select="setQuery('id_kategori_sub_fpp', $event)" class="w-100"
                                    placeholder="Pilih Pengajuan" label="name" track-by="id" v-model="selectedPengajuan"
                                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                                    :options="kategori_sub"></multiselect>
                            </div>
                        </b-form-group>
                    </div>

                    <div class="col-lg-4">
                        <b-form-group label="Status">
                            <div class="form-group">
                                <multiselect class="w-100" placeholder="Pilih Status" label="text" track-by="id"
                                    v-model="selectedStatus" :show-labels="false" :close-on-select="true"
                                    :clear-on-select="false" @select="setQuery('flag_status', $event)" :multiple="false"
                                    :options="option_status_fpp">
                                </multiselect>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col align-items-center mb-1 d-flex justify-content-end">
                        <b-button type="submit" variant="pgiBtn">
                            <i class="fa fa-search"></i> Cari
                        </b-button>
                        <b-button class="ml-2" variant="dark" @click="onResetQuery">
                            <i class="fa fa-refresh"></i> Reset
                        </b-button>
                    </div>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex"
import DateRangePicker from 'vue2-daterange-picker'
export default {
    components: { DateRangePicker },
    data() {
        return {
            filter: {
                per_page: 10
            },
            selectKabKot: null,
            selectedImNumber: null,
            selectedPengajuan: null,
            selectedJenisPengajuan: null,
            selectedKategoriPengajuan: null,
            selectedCabang: [],
            selectedStatus: null,
            dateRange: { startDate: null, endDate: null },
        }
    },
    methods: {
        handleSelectDate(value) {
            const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
            const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
            this.filter.startDate = startDate
            this.filter.endDate = endDate
            this.$store.dispatch("createQueryGlobal", this.filter)
        },
        setQuery(type, value) {
            console.log(value)
            if (type === 'im_number') {
                this.filter = {
                    ...this.query_global,
                    [type]: value,
                }
            } else {
                this.filter = {
                    ...this.query_global,
                    [type]: value.id,
                }
            }
            this.$store.dispatch("createQueryGlobal", this.filter)
        },
        async handleSearch() {
            const cabangIds = this.selectedCabang.map(i => i.id)
            this.filter = {
                ...this.filter,
                id_cabang_multiple: cabangIds.length ? cabangIds : undefined
            }
            this.$store.dispatch("createQueryGlobal", this.filter)
            this.$store.dispatch("getInternalMemo", this.query_global)
            this.$bvModal.hide("m-filter")
        },
        onResetQuery() {
            this.filter = {
                per_page: 10
            }
            this.selectKabKot = null
            this.selectedImNumber = null
            this.selectedCabang = []
            this.selectedPengajuan = null
            this.selectedJenisPengajuan = null
            this.selectedKategoriPengajuan = null
            this.selectedStatus = null
            this.dateRange = { startDate: null, endDate: null }
            this.hasFilter = false
            this.$store.dispatch("createQueryGlobal", this.filter)
            this.$store.dispatch("getInternalMemo", this.query_global)
        }
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        filtered_cities() {
            return this.$store.state.wilayah.filtered_cities
        },
        kategori_sub() {
            return this.$store.state.fpp.kategori_sub
        },
        kategori_jenis() {
            return this.$store.state.fpp.kategori_jenis
        },
        kategori_im() {
            return this.$store.state.fpp.kategori_im
        },
        option_status_fpp() {
            return this.$store.state.fpp.option_status_fpp
        },
        cabangLists() {
            return this.$store.state.global_data.cabangLists
        },
        query_params() {
            return this.$store.state.fpp.query_params
        },
        query_global() {
            return this.$store.state.persetujuan.query_global
        },
    },
    mounted() {
        this.$store.dispatch("getCityFiltered")
        this.$store.dispatch("getCategoryIM")
        this.$store.dispatch("getCategoryJenis")
        this.$store.dispatch("getCategorySub")
        this.$store.dispatch("getOptionStatusFpp")
        this.$store.dispatch("getCabang")
    }
}
</script>