<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5 v-if="role === 'General Affair'">Surat Tugas</h5>
        <h5 v-else>Persetujuan FPP (Formulir Pengajuan Permintaan, Perbaikan & Penggantian)</h5>
      </template>
      <TableInternalMemo />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex"
import TableInternalMemo from "../../components/fpp/persetujuansurattugas/tableInternamMemo.vue"
export default {
  components: { TableInternalMemo },
  data() {
    return {
      userId: localStorage.getItem("id"),
      role: localStorage.getItem("role"),
      katProses: JSON.parse(localStorage.getItem("k_proses"))[0],
    }
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
  },
  mounted() {
    this.$store.dispatch("getInternalMemo")
  }
}
</script>