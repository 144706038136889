<template>
    <div>
        <PageLoader v-if="isLoading" />
        <template v-if="internal_memo">
            <div class="row align-items-start mb-3">
                <div class="col-lg-12">
                    <FormFilterMemo />
                </div>
            </div>
            <template v-if="role === 'General Affair'">
                <div class="d-flex justify-content-start my-2">
                    <b-button @click="handleOpenFormSuratTugas" :disabled="id_memo_store.length < 1" variant="pgiBtn">
                        Buat Surat Tugas
                    </b-button>
                </div>
            </template>
            <template v-if="role === 'GM General Affair'">
                <div class="d-flex justify-content-srart my-2">
                    <b-button class="mr-2" @click="handleApproveMulti" :disabled="id_memo_store.length < 1"
                        variant="success">
                        <i class="fa fa-check"></i>
                        Setujui
                    </b-button>
                    <b-button @click="handleIgnoreMulti" :disabled="id_memo_store.length < 1" variant="danger">
                        <i class="fa fa-times"></i>
                        Tolak
                    </b-button>
                </div>
            </template>
            <b-table align-middle small responsive bordered hover show-empty :items="internal_memo.data" :fields="fields"
                class="mb-3 pgi-cutom-table">
                <template #empty="scope">
                    <span class="text-secondary">Data tidak ditemukan</span>
                </template>
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner small class="align-middle" variant="secondary"></b-spinner>
                        <span class="text-secondary"> Harap tunggu...</span>
                    </div>
                </template>
                <template v-slot:head(select)>
                    <div v-if="role !== 'GM Support' && role !== 'Area Manager'">
                        <b-form-checkbox :checked="checkedHeader" @change="handleSelectAll">
                        </b-form-checkbox>
                    </div>
                    <div v-else>#</div>
                </template>
                <template v-slot:cell(select)="row">
                    <template v-if="role !== 'GM Support' && role !== 'Area Manager'">
                        <b-form-checkbox v-if="row.item.flag !== katProses.proses || row.item.flag === 11"
                            v-model="row.item.checked" @change="handleSelectItem(row)">
                        </b-form-checkbox>
                        <template v-else>
                            <i class="fa fa-check text-success"></i>
                        </template>
                    </template>
                    <template v-else>
                        {{ row.index + 1 }}
                    </template>
                </template>
                <template v-slot:cell(im_number)="row">
                    <div>
                        <span class="d-block">{{ row.item.im_number }}</span>
                        <div>
                            <small>{{ row.item.created_at | moment("D MMM YYYY") }}</small>
                            <!-- <small class="ml-2" v-if="row.item.vendor_type === 0">vendor</small> -->
                        </div>
                    </div>
                </template>
                <template v-slot:cell(kategori_jenis)="row">
                    <div>
                        <span class="d-block">
                            {{ row.item.kategori_jenis ? row.item.kategori_jenis.name : "-" }}
                        </span>
                        <small>
                            {{ row.item.kategori_sub ? row.item.kategori_sub.name : "-" }}
                        </small>
                    </div>
                </template>
                <template v-slot:cell(kacab)="row">
                    <div>
                        {{ row.item.kepala_cabang ? row.item.kepala_cabang.name : "-" }}
                    </div>
                    <small>{{ row.item.kepala_cabang ? row.item.kepala_cabang.name : "-" }}</small>
                </template>
                <template v-slot:cell(flag)="row">
                    <FlagStatus :flag="row.item.flag" />
                </template>
                <template v-slot:cell(cabang)="row">
                    <div>
                        <span class="d-block">{{ row.item.cabang.name }}</span>
                        <small>{{ row.item.cabang.kabupaten_kota.name }}</small>
                    </div>
                </template>
                <template v-slot:cell(aksi)="row">
                    <b-button @click="handleDetailMemo(row.item.id)" variant="pgiBtn" size="sm">
                        <i class="fa fa-info-circle"></i>
                    </b-button>
                </template>
            </b-table>
            <div class="row justify-content-between">
                <div class="col-md-6 mb-2 mb-md-0">
                    <div class="page-size-container mb-1">
                        <div class="page-size-text">Tampilkan</div>
                        <select @change="changePerPage($event)" v-model="internal_memo.per_page" class="form-per-page"
                            id="inputGroupSelect01">
                            <option :value="10">10</option>
                            <option :value="20">20</option>
                            <option :value="30">30</option>
                            <option :value="40">40</option>
                            <option :value="50">50</option>
                        </select>
                        <div class="page-size-text">Baris</div>
                        <div class="ml-2">
                            <span>{{ internal_memo.from }}</span>
                            <span>-</span>
                            <span>{{ internal_memo.to }}</span>
                            <span class="mx-1">dari</span>
                            <span>{{ internal_memo.total }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-md-end">
                    <b-pagination class="mb-0" v-model="internal_memo.current_page" :total-rows="internal_memo.total"
                        :per-page="internal_memo.per_page" @input="changePage" aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </template>
        <b-modal id="m-surat-form-tugas" size="lg" title="Form Surat Tugas" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ModalSuratFormSuratTugasVue :query="query" />
        </b-modal>
        <b-modal id="m-ignore-multi" centered no-fade size="md" title="Catatan Penolakan" no-close-on-backdrop
            no-close-on-esc hide-footer>
            <b-form @submit.prevent="actionIgnoreMulti">
                <b-form-textarea v-model="textTolak"
                    placeholder="Jika anda yakin akan menolak, silahkan tuliskan catatan..." rows="3" max-rows="6">
                </b-form-textarea>
                <div class="d-flex justify-content-end mt-3">
                    <b-button type="submit" variant="danger">Tolak Memo</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="m-detail-arsip-fpp" size="xl" title="Detail Internal Memo" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ModalDetail :query="query_global" :memo="detailMemo" />
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex"
import DateRangePicker from 'vue2-daterange-picker'
import FlagStatus from './flagStatus.vue';
import ModalSuratFormSuratTugasVue from "./modalSuratFormSuratTugas.vue";
import FormFilterMemo from "./formFilterMemo.vue";
import ModalDetail from "../persetujuansurattugas/modalDetail.vue";
import PageLoader from "../../pageLoader.vue"
export default {
    components: { PageLoader, FlagStatus, ModalSuratFormSuratTugasVue, DateRangePicker, FormFilterMemo, ModalDetail },
    data() {
        return {
            textTolak: null,
            textSetuju: null,
            detailMemo: null,
            userId: localStorage.getItem("id"),
            role: localStorage.getItem("role"),
            katProses: JSON.parse(localStorage.getItem("k_proses"))[0],
            checkedHeader: false,
            id_memo: [],
            id_barang: [],
            quantity: [],
            cabang_kode: [],
            fields: [
                {
                    key: "select",
                    label: "",
                    thClass: "nameOfTheClassThSelect",
                    tdClass: "nameOfTheClassTd",
                },
                {
                    key: "im_number",
                    label: "No. Pengajuan",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                // {
                //     key: "tanggal",
                //     label: "Tanggal",
                //     thClass: "nameOfTheClassThTanggal",
                //     tdClass: "nameOfTheClassTd",
                //     sortable: true,
                // },
                {
                    key: "cabang",
                    label: "Cabang",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "kacab",
                    label: "Kacab",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "kategori_jenis",
                    label: "Pengajuan",
                    thClass: "nameOfTheClassTh",
                    sortable: true,
                },
                {
                    key: "flag",
                    label: "Status",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: true,
                },
                {
                    key: "aksi",
                    label: "Aksi",
                    thClass: "nameOfTheClassThAction",
                    tdClass: "nameOfTheClassTd",
                },
            ],
            query: {
                per_page: 10,
                page: 1
            },
        }
    },
    methods: {
        async handleDetailMemo(data) {
            const d = await this.$store.dispatch("getDetailMemo", data)
            this.detailMemo = d;
            this.$bvModal.show('m-detail-arsip-fpp')
        },
        handleSelectAll(value) {
            this.checkedHeader = value
            const newData = this.internal_memo.data
            this.internal_memo.data = newData.map(item => {
                if (item.flag !== this.katProses.proses || item.flag === 11) {
                    if (value) {
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                }
                return item
            })
            if (value) {
                const selectedMemo = this.internal_memo.data.filter(i => i.checked)
                this.id_memo = [...selectedMemo].map(i => i.id)
                this.$store.dispatch("createMemoIds", this.id_memo)
            } else {
                this.id_memo = []
                this.$store.dispatch("createMemoIds", this.id_memo)
            }
        },
        handleSelectItem(value) {
            let x;
            if (value.item.checked) {
                x = [...this.id_memo_store].filter(i => i !== value.item.id)
            } else {
                x = [...this.id_memo_store, value.item.id]
            }
            this.$store.dispatch("createMemoIds", x)
        },
        changePage(value) {
            this.query = {
                ...this.query_global,
                page: value,
            }
            this.$store.dispatch("createQueryGlobal", this.query)
            this.$store.dispatch("getInternalMemo", this.query_global)
        },
        changePerPage(data) {
            this.query = {
                ...this.query,
                per_page: data.target.value,
            }
            this.$store.dispatch("getInternalMemo", this.query)
            this.$store.dispatch("createQueryGlobal", this.query)
        },
        async handleOpenFormSuratTugas() {
            await this.$bvModal.show('m-surat-form-tugas')
        },
        // FILTER HANDLER
        handleSelectDate(value) {
            const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
            const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
            this.filter.startDate = startDate
            this.filter.endDate = endDate
        },
        // HANDLE APPROVE
        async handleApproveMulti() {
            this.$swal({
                title: "Konfirmasi",
                text: "Apakah anda yakin menambahkan surat tugas ini?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Saya menyetujui",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async result => {
                if (result.isConfirmed) {
                    const res = await this.$store.dispatch("approveMultipleMemo", this.id_memo_store)
                    if (res) {
                        this.$store.dispatch("getInternalMemo", this.query_global)
                        this.$store.dispatch("createMemoIds", [])
                    }
                }
            })

        },
        // HANDLE IGNORE
        handleIgnoreMulti() {
            this.$bvModal.show("m-ignore-multi")
        },
        async actionIgnoreMulti() {
            const data = {
                id: this.id_memo_store,
                catatan: this.textTolak
            }
            const res = await this.$store.dispatch("ignoreMultipleMemo", data)
            if (res) {
                this.$store.dispatch("getInternalMemo", this.query_global)
            }
            this.$bvModal.hide("m-ignore-multi")
            this.$store.dispatch("createMemoIds", [])
        }
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        id_memo_global() {
            return this.$store.state.persetujuan.id_memos
        },
        internal_memo() {
            return this.$store.state.persetujuan.internal_memo
        },
        id_memo_store() {
            return this.$store.state.persetujuan.id_memos
        },
        query_global() {
            return this.$store.state.persetujuan.query_global
        }
    }
}
</script>

<style>
.btn-popover {
    background-color: #000;
    color: #FFF;
    border: none;
    font-size: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.nameOfTheClassThSelect {
    width: 20px;
}

.form-per-page {
    width: 50px;
    text-align: center;
    border: 1px solid #eee;
    outline: none;
}

@media (max-width:1024px) {
    .pgi-cutom-table td:nth-of-type(1):before {
        content: "";
    }

    .pgi-cutom-table td:nth-of-type(2):before {
        content: "Pengajuan";
    }

    .pgi-cutom-table td:nth-of-type(3):before {
        content: "Cabang";
    }

    .pgi-cutom-table td:nth-of-type(4):before {
        content: "Kepala Cabang";
    }

    .pgi-cutom-table td:nth-of-type(5):before {
        content: "Pengajuan";
    }

    .pgi-cutom-table td:nth-of-type(6):before {
        content: "Status";
    }
}
</style>