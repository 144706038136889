<template>
    <div>
        <b-form @submit.prevent="handleSubmitSuratTugas">
            <div class="row">
                <div class="col-lg-6">
                    <b-form-group label="Tanggal Bertugas" label-for="date-maintenance" class="required">
                        <date-picker v-model="date" placeholder="Contoh: 25 Apr 2023" :config="config"></date-picker>
                    </b-form-group>
                </div>
                <div class="col-lg-6">
                    <b-form-group label="Pilih Type Maintenance" label-for="typeMaintenance">
                        <b-form-select id="typeMaintenance" v-model="typeMaintenanceSelected"
                            :options="typeMaintenance"></b-form-select>
                    </b-form-group>
                </div>

                <div class="col-lg-12">
                    <template v-if="typeMaintenanceSelected === 1">
                        <b-form-group label="Nama Vendor" label-for="typeMaintenance">
                            <b-form-input placeholder="nama vendor" v-model="vendorName"></b-form-input>
                        </b-form-group>
                    </template>
                    <template v-if="typeMaintenanceSelected === 0">
                        <b-form-group label="Petugas" class="required">
                            <multiselect :option-height="104" placeholder="Pilih Petugas" label="nama" track-by="id"
                                v-model="maintentance" :taggable="false" :show-labels="false" :close-on-select="false"
                                :clear-on-select="false" :preserve-search="true" :multiple="true"
                                :options="user_maintenance">
                                <template slot="option" slot-scope="props">
                                    <div class="item-option-custom">
                                        <img @error="handleImageError" class="option__image" :src="props.option.foto"
                                            alt="">
                                        <div class="option__desc">
                                            <span class="option__nama">{{ props.option.nama }}</span>
                                        </div>
                                    </div>
                                </template>
                            </multiselect>
                        </b-form-group>
                    </template>
                </div>
                <!-- TABLE KEARANJANG -->

                <div class="col-lg-12" v-if="typeMaintenanceSelected === 0">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="m-1">
                            <template v-if="keranjang.length">
                                <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                Keranjang Material
                            </template>
                        </div>
                        <div class="my-2">
                            <b-button @click="handleShowBarang" variant="pgiBtn">
                                <i class="fa fa-plus"></i> Material
                            </b-button>
                        </div>
                    </div>
                    <table class="table table-sm table-borderless" v-if="keranjang.length">
                        <thead>
                            <tr>
                                <th>Nama Barang</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-top" v-for="k in keranjang" :key="k.id_barang">
                                <td>
                                    {{ k.tipe }}
                                    <small class="d-block"> stok tersedia : {{ k.jumlah_stok }}</small>
                                </td>
                                <td class="text-right">
                                    <div class="count-item-keranjang">
                                        <button @click="handleDecreaseItem(k)" type="button">
                                            <i class="fa fa-minus"></i>
                                        </button>
                                        <input v-model="k.quantity" />
                                        <button :class="k.quantity === k.jumlah_stok ? 'disabled' : ''"
                                            :disabled="k.quantity === k.jumlah_stok" @click="handleIncreaseItem(k)"
                                            type="button">
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex mt-3 justify-content-end">
                <b-button :disabled="isLoading" type="submit" variant="pgiBtn">
                    Submit Surat Tugas
                </b-button>
            </div>
        </b-form>

        <b-modal id="m-list-barang" size="lg" title="Daftar Material" no-close-on-backdrop no-close-on-esc hide-footer>
            <template v-if="barangList">
                <div class="row justify-content-end">
                    <div class="col-6">
                        <b-form-input @input="handleSearchBarang" placeholder="cari barang..."></b-form-input>
                    </div>
                </div>
                <table class="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th>Material/Bahan</th>
                            <th>Stok</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-top" v-for="barang in barangList.data" :key="barang.id">
                            <td>
                                <template v-if="barang.barang_tipe">
                                    {{ barang.barang_tipe.tipe }}
                                </template>
                                <template v-else>{{ barang.nomer_barang }} </template>
                            </td>
                            <td>
                                {{ barang.jumlah_stok }} {{ barang.satuan }}
                            </td>
                            <button :disabled="barang.jumlah_stok < 1" @click="tambahKeranjangItem(barang)" :style="{
                                backgroundColor: barang.jumlah_stok > 0 ? 'black' : '#888',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                marginTop: '4px',
                                outline: 'none'
                            }">
                                <i class="fa fa-plus"></i>
                            </button>
                        </tr>
                    </tbody>
                </table>
                <div class="d-md-flex justify-content-center">
                    <b-pagination v-model="barangList.current_page" @input="changePageMaterial"
                        :total-rows="barangList.total"></b-pagination>
                </div>
            </template>
            <div class="d-flex justify-content-end">
                <b-button @click="$bvModal.hide('m-list-barang')" variant="dark">Selesai</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from "vuex"
import axiosClient from "../../../config/api"
export default {
    props: ["query"],
    data() {
        return {
            keranjang: [],
            date: null,
            typeMaintenance: [
                {
                    value: null,
                    text: "Pilih Type",
                },
                {
                    value: 0,
                    text: "Internal",
                },
                {
                    value: 1,
                    text: "Vendor",
                },
            ],
            vendorName: null,
            typeMaintenanceSelected: null,
            maintentance: null,
            config: {
                format: "D MMM YYYY",
                useCurrent: true,
                minDate: null,
            },
        }
    },
    methods: {
        tambahKeranjangItem(data) {
            console.log(data, "DATA")
            const idSelected = data.id_tipe
            const memoBarangSelected = this.keranjang.find(i => i.id_barang === idSelected)
            if (memoBarangSelected) {
                // JIKA ADA UPDATE
                this.keranjang.map(item => {
                    if (item.id_barang === idSelected) {
                        item.quantity = parseInt(item.quantity) + 1
                    }
                    return item
                })
            } else {
                // JIKA TIDAK APPEND
                const newDataBarang = {
                    id_barang: idSelected,
                    jumlah_stok: data.jumlah_stok,
                    quantity: 1,
                    tipe: data.barang_tipe.tipe,
                    pic: data.pic
                }
                this.keranjang.push(newDataBarang)
            }
            this.$helper.toastSucc(this, "Material berhasil ditambahkan")
        },
        handleShowBarang() {
            this.$bvModal.show('m-list-barang')
        },
        handleSearchBarang(value) {
            if (value.length > 1) {
                this.$store.dispatch("getStockBarangFpp", {
                    tipe: value
                })
            }
        },
        changePageMaterial(value) {
            this.$store.dispatch("getStockBarangFpp", {
                page: value
            })
        },
        handleImageError(e) {
            e.target.src = "/img/brand/user.png"
        },
        handleSubmitSuratTugas() {
            const isOverQty = this.keranjang.filter(i => i.quantity > i.jumlah_stok)
            if (isOverQty.length > 0) {
                this.$helper.toastErr(this, "Salah satu barang melebihi stok")
            } else {
                let formSurat;
                if (this.typeMaintenanceSelected === 0) {
                    // INTERNAL
                    formSurat = {
                        "vendor_type": this.typeMaintenanceSelected,
                        "date": this.$moment(this.date).format("YYYY-MM-D"),
                        "id_user_maintenance": this.maintentance.map(i => i.id),
                        "id_memo": this.id_memo.map(i => i),
                        "id_barang": this.keranjang.map(i => i.id_barang),
                        "pic": this.keranjang.map(i => i.pic),
                        "quantity": this.keranjang.map(i => parseInt(i.quantity))
                    }
                } else {
                    formSurat = {
                        "vendor_type": this.typeMaintenanceSelected,
                        "date": this.$moment(this.date).format("YYYY-MM-D"),
                        "id_memo": this.id_memo.map(i => i),
                        "vendor_name": this.vendorName
                    }
                }
                this.actionSubmitSuratTugas(formSurat)

            }
        },
        async actionSubmitSuratTugas(data) {

            this.$swal({
                title: "Konfirmasi Persetujuan",
                text: "Apakah anda yakin menyetujui pengajuan ini?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Saya menyetujui",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async result => {
                if (result.isConfirmed) {
                    this.$store.dispatch("createMemoIds", [])
                    try {
                        const response = await axiosClient({
                            method: 'POST',
                            url: 'internal-memo/memo/surat-tugas-maintenance',
                            data: data
                        });
                        if (response.status === 200) {
                            this.$helper.toastSucc(this, "Sukses Membuat Surat Tugas")
                            this.$store.dispatch("getInternalMemo", this.query)
                        }
                    } catch (error) {
                        this.$helper.toastErr(this, "Gagal Membuat Surat Tugas")
                    } finally {
                        this.$bvModal.hide("m-surat-form-tugas")
                        this.$store.dispatch("createMemoIds", [])
                    }
                }
            })
        },
        handleDecreaseItem(item) {
            if (item.quantity > 1) {
                item.quantity = parseInt(item.quantity) - 1
            } else {
                this.keranjang = this.keranjang.filter(i => i.id_barang !== item.id_barang)
            }
        },
        handleIncreaseItem(item) {
            if (item.jumlah_stok > item.quantity) {
                item.quantity = parseInt(item.quantity) + 1
            } else {
                this.$helper.toastErr(this, "Stok tidak mencukupi")
            }
        },
    },
    computed: {
        ...mapState("loading", ["isLoading"]),
        user_maintenance() {
            return this.$store.state.fpp.user_maintenance
        },
        barangList() {
            return this.$store.state.fpp.barangList
        },
        id_memo() {
            return this.$store.state.persetujuan.id_memos
        },
    },
    mounted() {
        this.$store.dispatch("getUserMaintenance")
        this.$store.dispatch("getStockBarangFpp")
    }
}
</script>

<style lang="scss">
.item-option-custom {
    display: flex;
    padding: 5px;

    .option__image {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    .option__desc {
        .option__nama {
            flex: 16;
        }
    }
}

.count-item-keranjang {
    display: inline-flex;
    align-items: center;
    border: 1px solid #DDD;
    padding: 3px;
    border-radius: 6px;

    button {
        &.disabled {
            background-color: #888;
        }

        border: none;
        background-color: black;
        border-radius: 5px;
        width: 24px;
        height: 24px;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;


    }

    input {
        width: 50px;
        text-align: center;
        margin: 0 3px;
        border: none;
        outline: none;
    }
}
</style>